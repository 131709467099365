section.content .subSection.with-gallery {
    padding: 4em 2em;
    padding-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(255,255,255,0.4);
}

section.content .subSection:not(.with-gallery) .gallery {
    margin-top: 4em;
    margin-bottom: 4em;
}

section.content .subSection.with-gallery .gallery {
    margin-top: 3em;
    margin-bottom: 3em;
}

.gallery button.navButton {
    border-radius: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.85em;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    display: inline-block;
    border-color: rgba(255,255,255,0.8);
    font-size: 150%;
    color: rgba(244, 197, 54,0.9);
}

section.subSection.with-gallery button.navButton:hover {
    background-color: #305b94;
}

.gallery button:hover {
    cursor: pointer;
}

.carousel, .photo-comments {
    margin-left: 5em;
    max-width: calc(100% - 10em);
    position: relative;
}

.carousel button {
    top: calc(50% - 2em);
    position: absolute;
}

.carousel button.backButton {
    left: -5em;
}

.carousel button.nextButton {
    right: -5em;
}

.gallery-image-container {
    list-style: none;
    padding-left: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    vertical-align: top;
}

.gallery-image-container .not-active {
    display: none;
}

.gallery-image-container .prev,
.gallery-image-container .next {
    opacity: 0;
}

.gallery-image-container .prev .magnifyButton,
.gallery-image-container .next  .magnifyButton {
    display: none;
}

.gallery-image-container .active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}


.gallery-image-container li {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 20em;
}

.gallery-image-container:not(.fade) li {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}


.gallery-image-container .gallery-image,
.gallery-image-container .caption {
    width: 50%;
    box-sizing: border-box;
}

.gallery-image-container.simple .gallery-image {
    width: 100%;
}

.gallery-image-container.simple li {
    height: 35em;
}

gallery-image-container .gallery-image {
    height: 100%;
}

.gallery-image-container .gallery-image .image {
    height: 100%;
    background-color: rgba(255,255,255,0.35);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

.caption {
    background-color: rgba(255,255,255,0.35);
    margin-left: 1em;
    margin-right: 1px;
    padding: 1em;
    overflow-y: auto;
}

.caption:after {
    content: " ";
    height: 3em;
    width: calc(50% - 1.75em);
    bottom: 1px;
    left: calc(50% + 0.5em + 1px);
    position: absolute;
    background-color: rgba(255,255,255,0.001);
    background-image: -moz-linear-gradient(rgba(255,255,255,0.001), white);
    background-image: -webkit-linear-gradient(rgba(255,255,255,0.001), white);
    background-image: -o-linear-gradient(rgba(255,255,255,0.001), white);
    background-image: -ms-linear-gradient(rgba(255,255,255,0.001), white);
    background-image: linear-gradient(rgba(255,255,255,0.001), white);
}

.caption p {
    text-align: justify;
    line-height: 2em;
}

.gallery-image-container .caption {
    border: 1px solid #305b94;
}

li:nth-child(2) .gallery-image-container .caption,
li:nth-child(4) .gallery-image-container .caption,
li:last-child .gallery-image-container .caption {
    border-color: #f4c536;
}

.caption .touchHint, .caption .clickHint {
    color: rgba(244, 197, 54,0.9);
    font-size: 90%;
    font-family: "Special Elite";
}


.gallery-image-container.fade {
    position: relative;
    display: block;
    height: 20em;
    overflow: hidden;
}

.gallery-image-container.fade li {
    width: 100%;
    -webkit-transition: opacity 2s;
    -o-transition: opacity 2s;
    transition: opacity 2s;
    z-index: 1;
    position: relative;
}

.gallery-image-container.fade li.active {
    transition-delay: 1s;
}

.gallery-image-container.fade li:nth-child(2) {
    transform: translateY(-100%);
    pointer-events: none;
}

.gallery-image-container.fade li.active:nth-child(2) {
    pointer-events: inherit;
}

.gallery-image-container:not(.fade) .prev {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    width: 0;
}

.gallery-image-container:not(.fade) .next {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    width: 0;

}

.gallery-image-container.fade .gallery-image .image {
    background-color: transparent;
}

.gallery-image-container.fade:after {
    content: " ";
    background-color: rgba(255,255,255,0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 0;
}

.gallery.fade .interactionHint {
    display: none;
}

/*.likeList > .prefix:before {*/
    /*content: "❤️";*/
    /*margin-right: 0.5em;*/
    /*opacity: 0.7;*/
/*}*/

.likeList {
    overflow-x: hidden;
    white-space: nowrap;
    margin-left: 1em;
    margin-right: 1em;
    text-overflow: ellipsis;
}

.likeList .shortList {
    display: none;
}

.likeList .list:not([title=""]) {
    cursor: pointer;
}

section.content .subSection.with-gallery.with-comments .gallery {
    margin-bottom: 0;
}

.photo-comments {
    margin-bottom: 3em;
}

.photo-comments ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.photo-comments .editableComment, .photo-comments .photo-comments {
    position: relative;
}

.photo-comments a.toggle {
    position: absolute;
    right: -4em;
    top: 4em;
}

.editableComment .editableMessage {
    width: 100%;
}

.editableComment .editableMessage .editableField textarea {
    border-radius: 0.5em;
    height: 4.5em;
    width: 100%;
}

.photo-comments .editableMessage .editableField textarea {
    height: 3.25em;
    margin-bottom: -1em;
}

.photo-comments .message:not(.alert) {
    background-color: rgba(250,250,250,0.9);
    border-radius: 0.5em;
    padding: 1em;
    margin-bottom: 1em;
    position: relative;
}

.photo-comments .message .commentName,
.photo-comments .message .content,
.photo-comments .message .editableMessage {
    display: inline-block;
    vertical-align: top;
}

.photo-comments .message .commentName {
    font-family: "Special Elite", Arial, sans-serif;
    color: #305b94;
    min-width: 8em;
}

.photo-comments .message .commentName {
    margin-right: 1em;
}

.photo-comments  .message .content {
    margin-bottom: 0;
    white-space: pre-wrap;
}

.photo-comments  .message.editable .content {
    width: 100%;
}

.photo-comments .message .content .staticValue {
    background-image: none;
    padding: 0;
    width: auto;
    font-family: inherit;
}

.photo-comments .message .content .staticValue,
.photo-comments .message > .content {
    line-height: 1.25em;
    font-size: 85%;
}

.photo-comments .message.editable .editableMessage,
.photo-comments .message > .content {
    width: calc(100% - 12em);
}

.photo-comments .buttonContainer.iconButtons {
    position: absolute;
    margin-top: 0;
    right: -1.5em;
    top: 1.5em;
}

.photo-comments .editableField .buttonContainer.iconButtons {
    right: -2.5em;
    top: 0.5em;
}

.photo-comments .message.editable .editableField + .buttonContainer.iconButtons {
    right: -1.25em;
    top: 2em;
}

.photo-comments .message.editable .editableField + .buttonContainer.iconButtons.editButtons {
    top: -0.5em;
    right: -4em;
}

.photo-comments .buttonContainer.iconButtons.editButtons button.undo {
    display: block;
}

.photo-comments .message.editable .editableField .buttonContainer {
    right: 1.25em;
    top: 2em;
}


.photo-comments .iconButtons button, .photo-comments .editableField button {
    display: inline-block;
    font-size: 85%;
    border: 3px solid rgba(255,255,255,0.5);
    border-radius: 100%;
    width: 3em;
    height: 3em;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
}

.photo-comments .buttonContainer.iconButtons button:not(:last-child) {
    margin-right: 0;
}

.photo-comments .iconButtons button:hover, .photo-comments .editableField button:hover {
    opacity: 1;
    background-color: white;
    border-color: white;
    width: 3.25em;
    height: 3.25em;

}

.translatableMessageContent + a {
    display: block;
    margin-top: 1em;
}

.iconButtons button.submit {
    background-image: url("../images/tick.png");
}

.iconButtons button.clear {
    background-image: url("../images/eraser.png");
}

.iconButtons button.view,
.editableComment .iconButtons button.clear {
    background-image: url("../images/cross.png");
}

.iconButtons button.edit {
    background-image: url("../images/pencil.png");
}

.iconButtons button.delete {
    background-image: url("../images/trash.png");
}

.iconButtons button.undo {
    background-image: url("../images/undo.png");
}


@media (pointer:fine) {
    .caption .touchHint {
        display: none;
    }
}

@media (pointer:coarse) {
    .caption .clickHint {
        display: none;
    }
}

.thumbs {
    width: 100%;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    white-space : nowrap;
    overflow-x: auto;
}

.thumb {
    list-style: none;
    height: 8em;
    cursor: pointer;
    display: inline-block;
}

.thumb:not(:last-child) {
    margin-right: 1em;
}

.thumb .gallery-image .image {
    height: 100%;
    width: 10em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: rgba(255,255,255,0.5);
    border: 1px solid white;
    opacity: 0.8;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

.thumb:hover .gallery-image .image, .thumb:active .gallery-image .image, .thumb.image-in-carousel .gallery-image .image {
    opacity: 1;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: white;
}

.thumb.image-in-carousel .gallery-image .image {
    border: 1px solid #9eb1bb;
    cursor: default;
}

.phoneCaptions {
    display: none;
}

.no-counter .counter {
    display: none;
}

@media (max-width: 1000px) {

    .likeList .shortList {
        display: block;
    }

    .likeList .longList {
        display: none;
    }

}

@media (max-width: 700px) {

    .gallery {
        position: relative;
        padding-left: 5.5em;
    }

    .gallery.simple {
        padding-left: 0;
    }

    ul.thumbs {
        position: absolute;
        top: 0;
        left: 0;
        width: 5em;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    li.thumb {
        display: block;
        height: 2.75em;
        margin-bottom: 0.5em;
    }

    .carousel, .photo-comments {
        margin-left: 0.25em;
        max-width: calc(100% - 0.5em);
    }

    .photo-comments .message.editable .editableMessage, .photo-comments .message > .content {
        width: 100%;
    }

    .likeList {
        font-size: 90%;
    }

    .gallery:not(.simple) .carousel button.backButton,
    .gallery:not(.simple) .carousel button.nextButton {
        display: none;
    }

    .gallery.simple .carousel button.backButton {
        left: -2em;
    }

    .gallery.simple .carousel button.nextButton {
        right: -2em;
    }

    .gallery-image-container.simple li {
        height: 20em;
    }

    .gallery-image-container .gallery-image {
        width: 100%;
        display: block;
    }

    .phoneCaptions {
        display: block;
        list-style: none;
        padding-left: 0;
        margin-bottom: 2.5em;
    }

    .phoneCaptions.short {
        margin-left: -6em;
    }

    .phoneCaptions .phoneCaption {
        display: block;
        height: 20em;
        overflow-y: auto;
        margin-left: 0;
        margin-top: 1em;
    }

    .phoneCaptions .caption:after {
        content: " ";
        height: 3em;
        width: calc(100% - 6.5em - 1px);
        left: 5.5em;
        bottom: 0;
        pointer-events: none;
    }

    .phoneCaptions.short .caption:after {
        left: -0.5em;
        width: calc(100% - 0.5em - 1px);
    }

    .phoneCaption button {
        bottom: 0.5em;
    }

    .phoneCaption .buttonContainer {
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        margin-bottom: 2em;
        text-align: center;
    }

    .gallery.fade .buttonContainer {
        display: none;
    }

    .gallery-image-container .caption, .phoneCaptions .not-active {
        display: none;
    }

    .gallery-image-container .gallery-image {
        height: 20em;
    }

    .thumb {
        height: 4em;
    }

    .thumb .gallery-image {
        width: 5em;
    }

    .thumb .gallery-image .image {
        height: 3.25em;
        width: 5em;
    }

}

@media (max-width: 700px) and (pointer:coarse) {

    .gallery.simple .carousel {
        margin-bottom: 3em;
    }

    .gallery.simple .carousel button.backButton{
        display: none;
    }

    .gallery.simple .carousel button.nextButton {
        color: transparent;
        background: transparent;
        border: none;
        font-size: 90%;
        top: auto;
        left: 0;
        bottom: -3.25em;
        text-align: center;
        width: 100%;
    }

    section.subSection.with-gallery button.navButton:hover {
        background: transparent;
    }

    .gallery.simple .carousel button.nextButton:after {
        content: "Swipe left on the photo or tap here to view the next one...";
        margin-bottom: 1em;
        display: block;
        font-size: 90%;
        color: #dc8b3b;
        font-family: "Special Elite", Arial, sans-serif;
        padding-left: 1em;
        padding-right: 1em;
    }

    .galleryContainer.es .gallery.simple .carousel button.nextButton:after {
        content: "Swipe a la izquierda en la foto o toca aquí para avanzar...";
    }

}

@media (max-width: 420px) {

    section.content .subSection.with-gallery .gallery {
        margin-left: -2em;
        margin-right: -2em;
    }

    .gallery .buttonContainer button.navButton {
        font-size: 120%;
    }

}