.thanksBackground {
    background-image: url("../images/background-thanks.jpg");
}

.website-1.image {
    background-image: url("../images/thanks/website-1.jpg");
}

.website-1.image {
    background-image: url("../images/thanks/website-1-thumb.jpg");
}

.website-2.image {
    background-image: url("../images/thanks/website-2.jpg");
}

.website-2.image {
    background-image: url("../images/thanks/website-2-thumb.jpg");
}

.website-3.image {
    background-image: url("../images/thanks/website-3.jpg");
}

.website-3 .image {
    background-image: url("../images/thanks/website-3-thumb.jpg");
}

.announcement-1.image {
    background-image: url("../images/thanks/announcement-1.jpg");
}

.announcement-2.image {
    background-image: url("../images/thanks/announcement-2.jpg");
}

.announcement-3.image {
    background-image: url("../images/thanks/announcement-3.jpg");
}

.announcement-4.image {
    background-image: url("../images/thanks/announcement-4.jpg");
}

.announcement-5.image {
    background-image: url("../images/thanks/announcement-5.jpg");
}

.sand-1.image {
    background-image: url("../images/thanks/sand-1.jpg");
}

.sand-2.image {
    background-image: url("../images/thanks/sand-2.jpg");
}

.sand-3.image {
    background-image: url("../images/thanks/sand-3.jpg");
}

.sand-4.image {
    background-image: url("../images/thanks/sand-4.jpg");
}

.sand-5.image {
     background-image: url("../images/thanks/sand-5.jpg");
 }

.sand-6.image {
    background-image: url("../images/thanks/sand-6.jpg");
}

.rings-1.image {
    background-image: url("../images/thanks/rings-1.jpg");
}

.rings-2.image {
    background-image: url("../images/thanks/rings-2.jpg");
}

.rings-3.image {
    background-image: url("../images/thanks/rings-3.jpg");
}

.rings-4.image {
    background-image: url("../images/thanks/rings-4.jpg");
}

.rings-5.image {
    background-image: url("../images/thanks/rings-5.jpg");
}

.rings-6.image {
    background-image: url("../images/thanks/rings-6.jpg");
}

.permit.image {
    background-image: url("../images/thanks/permit.jpg");
}

.question.image {
    background-image: url("../images/thanks/question-mark.jpg");
}

.flowers.image {
    background-image: url("../images/thanks/farm.jpg");
}

.ceremony.image {
    background-image: url("../images/thanks/ceremony-1.jpg");
}

.cookies.image {
    background-image: url("../images/thanks/the-wildflour-bakehouse.jpg");
}

.photography.image {
    background-image: url("../images/thanks/juicebeats.jpg");
}

.family-1.image {
    background-image: url("../images/thanks/family-1.jpg");
}

.family-2.image {
    background-image: url("../images/thanks/family-2.jpg");
}

.family-3.image {
    background-image: url("../images/thanks/family-3.jpg");
}

.family-4.image {
    background-image: url("../images/thanks/family-4.jpg");
}

.family-5.image {
    background-image: url("../images/thanks/family-5.png");
}

.nic.image {
    background-image: url("../images/thanks/nic.jpg");
}

.martin.image {
    background-image: url("../images/thanks/martin.png");
}

.backgroundElements.us-thanks,
.backgroundElements.flowers {
    height: 20em;
    width: 20em;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.8;
}

.backgroundElements.us-thanks {
    background-image: url("../images/us-elements-thanks-low.png");
}

.backgroundElements.flowers {
    background-image: url("../images/flowers-elements-thanks.png");
    height: 15em;
}

section.subSection.with-gallery {
    position: relative;
}

section.subSection.with-gallery:not(:last-of-type):after {
    content: " ";
    position: absolute;
    top: calc(100% + 0.5em);
    left: calc(50% - 0.5em);
    z-index: 100;
    width: 2em;
    height: 2em;
    display: block;
    background-size: contain;
    background-image: url("../images/down.png");
    background-repeat: no-repeat;
}

section.subSection.with-gallery.before-us-thanks:after {
    margin-top: 10em;
}