h2.banner {
    font-family: "FerinittaWeb", "Times New Roman", serif;
    text-align: center;
    font-size: 500%;
    position: absolute;
    z-index: 500;
    margin-top: -1.75em;
    left: calc(50% - 4.85em);
}

.mainBackground.homeBackground {
    margin-top: 10em;
}

.link-play-video {
    background-image: url("../images/old-tv.png");
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10em;
    width: 10em;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 1em;
    margin-bottom: 5em;
}

.link-play-video:hover, .link-play-video:active {
    background-image: url("../images/old-tv-on.png");
    transition: all 0.3s;
}

.link-play-video .hint {
    margin-top: 11em;
    display: block;
}

.link-play-video .hint.first {
    display: none;
}

.homeBackground {
    background-image: url("../images/background-home.jpg");
}

.background404 {
    background-image: url("../images/background-404.jpg");
}

.closeButton {
    background-image: url("../images/close-button-white.png");
}

@media (max-width: 900px) {

    h2.banner {
        font-size: 7.5vw;
        transition: font-size 0.15s;
        margin-top: -16.5vw;
    }

    .mainBackground.homeBackground {
        margin-top: 20vw;
    }

}

@media (max-width: 400px) {

    h2.banner {
        font-size: 6.5vw;
    }

}

.contentWrapper.home.welcome-video-played-false header ul,
.contentWrapper.home.welcome-video-played-false header .hamburger,
.contentWrapper.home.welcome-video-played-false header .protected-controls,
.contentWrapper.home.welcome-video-played-false header .mainPageTitle,
.contentWrapper.home.welcome-video-played-false h2.banner,
.contentWrapper.home.welcome-video-played-false .mainBackground,
.contentWrapper.home.welcome-video-played-false footer .elopementDate {
    display: none;
}

.contentWrapper.home.welcome-video-played-false footer {
    border-top: none;
}

.contentWrapper.home.welcome-video-played-false a.link-play-video {
    margin-top: calc(50vh - 20em);
    margin-bottom: calc(50vh - 15em);
}

.contentWrapper.home.welcome-video-played-false {
    width: 30%;
    min-width: 320px;
}

.contentWrapper.home.welcome-video-played-false .hint.after {
    display: none;
}

.contentWrapper.home.welcome-video-played-false .hint.first {
    width: 5em;
    margin-left: auto;
    margin-right: auto;
}

@media (pointer: fine) {
    .contentWrapper.home.welcome-video-played-false .hint.first.pointer {
        display: block;
    }
}

@media (pointer: coarse) {
    .contentWrapper.home.welcome-video-played-false .hint.first.touch {
        display: block;
    }
}
