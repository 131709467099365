@font-face {
    font-family: 'FerinittaWeb';
    src: url('../fonts/Ferinitta.woff') format('woff'),
    url('../fonts/ferinitta-webfont.woff2') format('woff2'),
    url('../fonts/Ferinitta.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

footer {
    margin: 1em;
    margin-top: 2em;
    padding: 0.25em;
    padding-left: 1em;
    padding-right: 1em;
    border-top: 1px solid #354660;
}

footer.not-logged-in {
    border-top: none;
    padding-top: 1.5em;
}

footer .elopementDate .parentheses {
    font-family: "FerinittaWeb", Arial, sans-serif;
    font-size: 130%;
    color: #dc8b3b;
}

footer .elopementDate .gearsAnnouncementWrapper {
    text-transform: uppercase;
    color: #9eb1bb;
}

footer .notices {
    font-size: 70%;
    display: flex;
}

footer .notices span {
    width: 50%;
}

footer .copyrightNotice {
    text-align: left;
}

footer .poweredByNotice {
    text-align: right;
}

footer .gearRings {
    width: 4em;
    height: 3em;
    padding-left: 0.75em;
    padding-right: 0.5em;
    vertical-align: bottom;
}

footer .reactLogo {
    height: 1.5em;
    width: 1.5em;
    padding-left: 0.5em;
    vertical-align: middle;
    padding-bottom: 0.25em;
}


@media (min-width: 551px) {

    footer .elopementDate .gearsAnnouncementWrapper span {
        display: inline-block;
        width: 11em;
    }

    footer .elopementDate .gearsAnnouncementWrapper span:first-child span {
        text-align: right;
    }

    footer .elopementDate .gearsAnnouncementWrapper span:last-child span {
        text-align: left;
    }
}

@media (max-width: 550px) {

    footer .elopementDate .gearsAnnouncementWrapper span {
        display: block;
    }

    footer .elopementDate .gearsAnnouncementWrapper span:first-child {
        padding-bottom: 0.25em;
        padding-top: 0.25em;
    }

    footer .elopementDate .gearsAnnouncementWrapper span:last-child {
        padding-top: 0.5em;
        margin-bottom: -0.25em;
    }
}

@media (max-width: 420px) {

    footer {
        font-size: 90%;
    }

}