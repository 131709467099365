

.storyBackground-off {
    background-image: url("../images/background-story.jpg");
}

.storyBackground-on {
    background-image: url("../images/background-story-lit.jpg");
    z-index: 50;
}

.light-off .storyBackground-on {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.light-on .storyBackground-on {
    opacity: 50;
    transition: opacity 0.5s ease-in-out;
}

.light-off p, .light-off .buttonWrapper {
    opacity: 0;
}

.light-link {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 3.75em;
    width: 3em;
    text-align: center;
}

.light-on .light-link {
    background-image: url("../images/light-on.png");
}

.light-off .light-link {
    background-image: url("../images/light-off.png");
}

.light-on p, .light-on .buttonWrapper {
    opacity: 100;
}

.contentWrapper.ourstory .light-on .mainBackgroundContainer:before,
.contentWrapper.ourstory .light-on .mainBackgroundContainer:after {
    opacity: 0.9;
}


.contentWrapper.ourstory .light-on .mainBackgroundContainer:before,
.contentWrapper.ourstory .light-on .mainBackgroundContainer:after,
.light-on p:first-child {
    transition: opacity 0.5s ease-in-out;
}

.light-on p:not(:first-child) {
    transition: opacity 1s ease-in-out;
    line-height: 2em;
    padding-left: 1em;
    padding-right: 1em;
}

.light-off .buttonWrapper {
    transition: opacity 0s ease-in-out;
}

.light-on .buttonWrapper {
    transition: opacity 2s ease-in-out;
    margin-top: 1.5em;
}

.light-off .hint {
    color: #f4c536;
}

.light-off .hint.es {
    width: 6em;
    margin-left: -1.5em;
}

.light-on .hint {
    color: lightgrey;
    opacity: 0;
}

.light-on .hint {
    transtion: color 0.25s ease-in-out;
}

.backgroundElements.graffiti {
    background-image: url("../images/graffiti-elements-story.png");
    width: 30em;
    margin-top: 2em;
    opacity: 0;
    height: 0;
    transition: opacity 0.2s, height 0.5s;
}

.light-on .backgroundElements.graffiti {
    opacity: 0.8;
    transition: opacity 1s 1s, height 0.5s 0s;
    height: 15em;
}
