.modal-hidden .modal-background {
    display: none;
}

.modal-visible .modal-background {
    height: 100vh;
    width: 100vw;
    opacity: 0.3;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 1100;
}