@import url("./modal-background.css");

.video-player-hidden > * {
    display: none;
}

.video-player-visible > * {
    display: block;
    position: fixed;
}

.video-player-visible .videoPlayerContainer {
    background-color: black;
    top: 0;
    left: 0;
    height: calc(100vh - 2em);
    width: calc(100vw - 3em);
    margin: 1em;
    box-sizing: content-box;
    z-index: 1200;
}



.video-player-visible iframe {
    width: 100%;
    height: 100%;
}

.video-player-visible .closeButton {
    top: -1em;
    right: -1em;
}

.video-player-visible ~ .homeBackground, .video-player-visible ~ .link-play-video {
    display: none;
}

.video-player-visible:after {
    content: " ";
    display: block;
    width: 1em;
    height: calc(100vh - 27em);
}


.video-player-visible.fullscreen .videoPlayerContainer {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.video-player-visible.fullscreen .closeButton {
    top: 0.25em;
    right: 1.5em;
}

@media (pointer: coarse) {

    .video-player-visible .closeButton {
        top: -2em;
        right: -0.75em;
    }

    .video-player-visible .videoPlayerContainer {
        margin-top: 2.5em;
        height: calc(100vh - 4em);
        width: calc(100vw - 2em);
    }

}

