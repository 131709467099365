.languageToggle {
    position: relative;
    display: inline-block;
    border: 1px solid black;
    border-radius: 2em;
    height: 3.2em;
    cursor: pointer;
    background-color: rgba(255,255,255,0.6);
    background-image: url("../images/down-arrow.png");
    background-position: calc(100% - 0.75em) 1.35em;
    padding-right: 1em;
    background-size: 0.8em;
    background-repeat: no-repeat;
}

.languageToggle:hover {
    background-color: white;
}

.languageToggle .currentSelection,
.languageToggle .option {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 3.5em;
    padding-right: 1em;
    background-repeat: no-repeat;
    background-size: 2em 2em;
    background-position: 1em 0.5em;
    min-height: 1em;
}

.languageToggle.en .currentSelection,
.languageToggle .option.en {
    background-image: url("../images/english.png");
}

.languageToggle.es .currentSelection,
.languageToggle .option.es  {
    background-image: url("../images/spanish.png");
}

.language-options {
    display: none;
    position: absolute;
    width: 11em;
    top: calc(-50% + 1em);
    left: calc(-50% + 5em);
    background-color: #f5eeec;
    border: 1px solid black;
    z-index: 1;
}

.language-options.show {
    display: block;
}

.language-options .option.selected {
    background-color: rgba(255,255,255,0.6);
}

.language-options .option:not(.selected):hover {
    background-color: rgba(255,255,255,0.95);
    text-decoration: underline;
}


.language-options .option.selected {
    cursor: default;
}

@media (max-width: 750px) {


    .languageToggle .currentSelection .description {
        display: none;
    }

    .language-options {
        left: calc(-50% + 2em);
    }

}