.notificationContainer {
    border: 1px solid;
    margin-top: 2em;
    padding: 2em;
    border-color: #305b94;
    background-color: rgba(255,255,255,0.4);
}

section.subSection:nth-child(odd) > .notificationContainer {
    border-color: #f4c536;
}

.notificationContainer h3 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.notificationContainer fieldset {
    border: none;
    padding: 0;
}


.notificationContainer .editableField, .notificationContainer .editableField > * {
    display: inline-block;
}

.notificationContainer .editableField {
    margin-right: 3em;
    width: calc(50% - 6em);
    vertical-align: top;
}

a.toggle.view {
    display: inline-block;
    margin-left: 1em;
}

a.toggle.view:before {
    content: "<-";
    display: inline-block;
    margin-right: 0.5em;
}

a.toggle.view:after {
    display: inline-block;
    margin-left: 0.5em;
}

a.toggle.edit {
    display: block;
    margin-top: 0.25em;
    float: right;
}

.notificationContainer button {
    vertical-align: middle;
}

.notificationContainer .editableField input {
    width: 100%;
    box-sizing: border-box;
}

.notificationContainer .editableField .staticValue {
    display: inline-block;
}

.notificationContainer .notificationContainer button {
    margin-left: 1em;
}

.notificationContainer .editableField .value {
    width: 100%;
}

.notificationContainer .editableField .value.withToggle {
    min-height: 4.5em;
}

.notificationContainer .editableField .value:not(.withToggle) {
    margin-bottom: 1.5em;
}


@media (max-width: 900px) {

    .notificationContainer a.toggle.view, .editableMessage .content a.toggle.view {
        display: block;
        margin-left: 0;
    }

    .notificationContainer a.toggle.view:before, .notificationContainer a.toggle.view:after,
    .editableMessage .content a.toggle.view:before,  .editableMessage .content a.toggle.view:after {
        content: "^";
    }

    .messagesSection .editableMessage .content .staticValue {
        width: 100%;
        margin-bottom: 0.5em;
    }

}

@media (max-width: 800px) {

    .notificationContainer fieldset {
        padding-top: 1em;
    }

    .notificationContainer a.toggle.view {
        display: inline-block;
        margin-left: 1em;
    }

    .notificationContainer a.toggle.view:before {
        content: "<-";
    }

    .notificationContainer a.toggle.view:after {
        content: none
    }

    .notificationContainer .editableField {
        display: block;
        width: 100%;
        margin-right: 0;
    }

    .notificationContainer button {
        margin-left: 0;
    }

}

@media (max-width: 500px) {

    .editableField a.toggle.view {
        display: block;
        margin-left: 0;
    }

    .editableField a.toggle.view:before, .editableField a.toggle.view:after {
        content: "^";
    }

    .notificationContainer button {
        margin-top: 1em;
    }


}