.bigDayBackground {
    background-image: url("../images/background-big-day.jpg");
}

.flight {
    background-image: url("../images/plane-taking-off.jpg");
}

.react-parallax-background.custom-bg.driving-route {
    background-image: url("../images/driving-route-low.png");
    background-position-y: bottom;
    background-size: 130%;
}

.gracehill {
    background-image: url("../images/gracehill.jpg");
}

.react-parallax-background.custom-bg.dolly {
    background-image: url("../images/dolly.jpg");
    background-position-y: bottom;
    background-size: 100%;
}

.react-parallax-background.custom-bg.cookies {
    background-image: url("../images/cookies.jpg");
    background-position-y: bottom;
    background-size: 100%;
}

.react-parallax-background.custom-bg.river {
    background-image: url("../images/river.jpg");
    background-size: 100%;
    background-position-y: 0;
}

.react-parallax-background.custom-bg.church {
    background-image: url("../images/church.jpg");
    background-position-y: 7em;
}

section.subSection:nth-child(odd) .react-parallax-content .church h2 {
    background-color: rgba(96, 130, 96,0.3);
}

.react-parallax-content .church h2:after{
    content: " ";
    background-image: url("../images/couple-elements-big-day.png");
    height: 5em;
    width: 5em;
    display: block;
    background-size:contain;
    background-repeat: no-repeat;
    position: absolute;
    top: calc(100% + 4em);
    left: calc(50% - 2em);
}


.backgroundElements.couple {
    background-image: url("../images/couple-elements-big-day.png");
    height: 20em;
    width: 20em;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;

}

.cove {
    background-image: url("../images/cade-cove.jpg");
}

.park {
    background-image: url("../images/abrams-falls.jpg");
}

.on-the-road {
    background-image: url("../images/on-the-road.jpg");
}


@media (max-width: 475px) {

    .react-parallax-content .church h2:after {
        top: calc(100% + 5em);
        height: 10em;
        width: 10em;
    }

    .react-parallax-background.custom-bg.on-the-road {
        background-position-x: -2em;
        background-position-y: -5em;
    }

}

.roadtrip {
    background-image: url("../images/roadtrip.png");
}

.react-parallax-background.custom-bg.cove, .react-parallax-background.custom-bg.park, .react-parallax-background.custom-bg.on-the-road {
    background-size: 100%;
}

.react-parallax-background.custom-bg.roadtrip {
    background-size: 150%;
}

.backgroundElements.trees {
    background-image: url("../images/tree-elements-big-day-low.png");
    background-position: left;
    height: 15em;
    width: 22em;
    margin-left: auto;
    margin-right: auto;
    background-repeat: repeat-x;
    background-size: contain;
    opacity: 0.8;
}

@media (max-width: 450px) {

    .backgroundElements.trees {
        height: 7.5em;
        width: 11em;
        transition: all 1s;
    }

}