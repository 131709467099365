
p.suggestion-hint:after {
    content: ":";
}

@media (min-width: 500px) {

    p.suggestion-hint,
    .caveat .logout-button,
    .caveat p.suggestion-hint:after{
        display: inline-block;
    }

    .caveat p.suggestion-hint:after {
        margin-right: 1em;
    }

}

@media (min-width: 650px) {

    .caveat p.suggestion-hint:after {
        content: " ";
        background-image: url("../images/right-arrow.png");
        width: 2em;
        height: 2em;
        vertical-align: middle;
        background-size: contain;
        margin-left: 1em;
        margin-right: 1em;
    }

}


@media (min-width: 700px) {

    .caveat p.suggestion-hint:after {
        width: 4em;
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
}


@media (min-width: 750px) {

    .caveat p.suggestion-hint:after {
        width: 6em;
        margin-left: 2em;
        margin-right: 2em;
    }
}

@media (min-width: 800px) {

    .caveat p.suggestion-hint:after {
        width: 8em;
    }
}

@media (min-width: 850px) {

    .caveat p.suggestion-hint:after {
        width: 10em;
    }
}

@media (min-width: 900px) {

    .caveat p.suggestion-hint:after {
        background-image: url("../images/right-arrow-two.png");
        opacity: 0.7;
        height: 2.5em;
        width: 18em;
    }
}
