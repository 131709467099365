/*
off white : #f5eeec
dark blue : #4a617f
medium blue : #305b94
light blue : #9eb1bb
dark gold : #dc8b3b
light gold : #e6c083
yellow: #f4c536;
grey: #354660
green : #8ab974
*/

@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Special+Elite');

html {
    background-color: white;
    min-width: 320px;
}

.contentWrapper {
    background-color: #f5eeec;
    margin: 1em;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: calc(100% - 2em);
    transition: width 0.25s ease-out;
}

body {
    margin: 0;
    font-family: "Montserrat", Arial, sans-serif;
    text-align: center;
    color: #354660;
}

h1, h2, h3 {
    color: #305b94;
    font-family: "Special Elite";
    text-align: left;
}

section.subSection {
    line-height: 2em;
}

section.subSection:nth-child(even) h2,
section.subSection:nth-child(even) h3 {
    color: #f4c536;
}

section.subSection .react-parallax-content h2 {
    color: #305b94;
    background-color: rgba(255,255,255,0.6);
    border-radius: 0.33em;
}

section.subSection:nth-child(odd) .react-parallax-content h2 {
    color: #ffdf7f;
    background-color: transparent;
}

h1 {
    font-size: 300%;
}

h2 {
    font-size: 200%;
}

h3 {
    font-family: "FerinittaWeb", "Times New Roman", serif;
    font-size: 180%;
    font-weight: normal;
}

@media (max-width: 475px) {

    h1 {
        font-size: 150%;
    }

    h2 {
        font-size: 145%;
    }

    h3 {
        font-size: 135%;
    }

}

a {
   color: #dc8b3b;
   font-family: "Special Elite", "Courier New", serif;
    cursor: pointer;
}

a:hover, a:active {
    color: #e6c083;
    text-decoration: underline;
}


a .hint {
    display: inline-block;
    cursor: pointer;
}

a.backToTheTopLink {
    margin-top: 1.5em;
    display: inline-block;
}


label {
    font-family: "Special Elite", Arial, sans-serif;
    margin-right: 1em;
    font-size: 120%;
}

input[type="text"], input[type="email"], textarea {
    border: none;
    padding: 0.5em;
    font-size: 100%;
    background-color: rgba(255,255,255,0.8);
    resize: none;
}

input[type="text"]:focus, input[type="email"]:focus {
    background-color: white;
}

button, .logout-button, a.button {
    display: inline-block;
    border: 1px solid #354660;
    border-radius: 0.25em;
    background-color: rgba(255,255,255,0.3);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;
}

button, .logout-button {
    font-size: 90%;
    padding: 0.75em;
}

.buttonContainer {
    margin-top: 1em;
}

.buttonContainer button:not(:last-child) {
    margin-right: 1.5em;
}

a.button {
    padding: 1.25em 1.25em 1em 1.25em;
}

button:hover, button:active, .logout-button:hover, .logout-button:active, a.button:hover, a.button:active  {
    color: white;
    background-color: #305b94;
    cursor: pointer;
    text-decoration: none;
}

section.subSection:nth-child(even) button:hover,
section.subSection:nth-child(even) button:active,
section.subSection:nth-child(even) .logout-button:hover,
section.subSection:nth-child(even) .logout-button:active {
    background-color: #f4c536;
}

section.content {
    position: relative;
}

section.content section.subSection {
    margin: 2em;
    margin-top: 3em;
    text-align: justify;
}

section.content .mainBackground + section.subSection {
    margin-top: 4em;
}

section.subSection h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.mainBackgroundContainer {
    position: relative;
}

.innerMainBackground {
    position: absolute;
    top: 0;
    left: 0;
}

.mainBackground, .mainBackgroundContainer, .innerMainBackground {
    width: 70vw;
    height: 70vw;
    max-width: calc(100vh - 15em);
    max-height: calc(100vh - 15em);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    display: block;
    transition: all 0.5s;
}


.mainBackground, .mainBackgroundContainer {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    border: 1em solid rgba(255,255,255,0.4);
}

.mainBackground:before, .mainBackground:after, .mainBackgroundContainer:before, .mainBackgroundContainer:after {
    content: " ";
    height: 70%;
    width: 15vw;
    display: block;
    position: absolute;
    top: 0;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 15%;
    opacity: 0.7;
    transition: opacity 0.5s;
}

.mainBackground:before, .mainBackgroundContainer:before {
    left: calc(-15vw - 1em);
}

.mainBackground:after, .mainBackgroundContainer:after {
    left: calc(100% + 1em);
}

.react-parallax-background.custom-bg {
    display: block;
    width: calc(1300px - 6em);
    height: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.react-parallax  {
    margin-top: 4em;
    margin-bottom: 4em;
}

section.subSection .react-parallax  {
    margin-left: -2em;
    margin-right: -2em;
}


.backgroundElements {
    display: block;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    max-width: calc(100% - 3em);
}

.closeButton {
    width: 2.5em;
    height: 2.5em;
    position: absolute;
    display: block;
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: black;
    border-radius: 100%;
    border: 2px solid white;
    background-position: 50% 50%;
}



.home .fade-enter .mainBackground, .home .fade-enter h2.banner,
.thankyou .fade-enter .mainBackground {
    opacity: 0;
}

.home .fade-enter-active .mainBackground, .home .fade-enter-active h2.banner,
.thankyou .fade-enter-done .mainBackground {
    opacity: 1;
    transition: opacity 1.5s;
}

.thewedding .fade-enter .mainBackground,
.thewedding .fade-enter .mainBackground:before,
.thewedding .fade-enter .mainBackground:after {
    opacity: 0;
}

.thewedding .fade-enter-active .mainBackground,
.thewedding .fade-enter-active .mainBackground:after,
.thewedding .fade-enter-active .mainBackground:before  {
    opacity: 0.5;
    transition-delay: 1s;
    transition: opacity 1s;
}

.thewedding .fade-enter .mainBackground:before,
.thewedding .fade-enter-active .mainBackground:before{
     transform: scale(0.95) translateX(2.5%);
 }

.thewedding .fade-enter .mainBackground:after,
.thewedding .fade-enter-active .mainBackground:after{
    transform: scale(0.95) translateX(-2.5%);
}

.thewedding .fade-enter-done .mainBackground,
.thewedding .fade-enter-done .mainBackground:before,
.thewedding .fade-enter-done .mainBackground:after  {
    opacity: 0.7;
    transform: scale(1);
    transition-delay: 1s;
    transition: opacity 2s, transform 1.5s;
}

.thewedding .fade-enter-done .mainBackground {
    opacity: 1;
}


.home .fade-enter-active h2.banner {
    transition: opacity 0.5s;
}

.ourstory .fade-enter .mainBackgroundContainer, .messages .fade-enter .mainBackground  {
    opacity: 0;
    -webkit-transform:  scale(0.95) translateY(2em);
    -ms-transform:  scale(0.95) translateY(2em);
    transform:  scale(0.95) translateY(2em);
}

.ourstory .fade-enter-done .mainBackgroundContainer, .messages .fade-enter-done .mainBackground  {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transition: opacity 1s, -webkit-transform 0.5s;
    transition: opacity 1s, -webkit-transform 0.5s;
    -o-transition: opacity 1s, transform 0.5s;
    transition: opacity 1s, transform 0.5s;
    transition: opacity 1s, transform 0.5s, -webkit-transform 0.5s;
}

 .photos .fade-enter .mainBackground, .celebrations .fade-enter .mainBackground {
    opacity: 0;
    -webkit-transform: translateY(-2em) scale(0.95);
    -ms-transform: translateY(-2em) scale(0.95);
    transform: translateY(-2em) scale(0.95);
}

 .photos .fade-enter-done .mainBackground, .celebrations .fade-enter-done .mainBackground{
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
    -webkit-transition: opacity 1s ease-out, -webkit-transform 0.5s ease-out;
    transition: opacity 1s ease-out, -webkit-transform 0.5s ease-out;
    -o-transition: opacity 1s ease-out, transform 0.5s ease-out;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    transition: opacity 1s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

button .loader {
    display: none;
}

button.loading-true .loader {
    font-size: 2px;
    display: inline-block;
    margin: 0;
    margin-left: 2.5em;
    float: right;
}

.loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: #f5eeec;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

button .loader:after {
    background: #305b94;
}

.comp404 {
    margin-top: 1em;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



@media (max-width: 1220px) {

    .mainBackground:before, .mainBackground:after, .mainBackgroundContainer:before, .mainBackgroundContainer:after {
        opacity: 0 !important;
        transition: opacity 0.3s;
    }
}

@media (max-width: 1100px) {

    .mainBackground:before, .mainBackground:after, .mainBackgroundContainer:before, .mainBackgroundContainer:after {
        content: none;
    }
}

@media (max-width: 650px) {

    .header-bar {
        padding-left: 0;
    }

    .mainBackground, .mainBackgroundContainer, .innerMainBackground {
        width: calc(100vw - 3em);
        height: calc(100vw - 3em);
        min-width: calc(320px - 2em);
        min-height: calc(320px - 2em);
        max-width: unset;
        max-height: unset;
        border: none !important;
        transition: all 0.5s;

    }
}

@media (orientation: landscape) and (max-height: 500px) {

    .mainBackground, .mainBackgroundContainer, .innerMainBackground {
        width: 100vh;
        height: 100vh;
        min-width: calc(320px - 2em);
        min-height: calc(320px - 2em);
        max-width: unset;
        max-height: unset;
        transition: all 0.5s;

    }
}