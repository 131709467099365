header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #f5eeec;
    padding: 1em;
    padding-bottom: 0;
    z-index: 1000;
}

@media (orientation: landscape) and (max-height: 500px) {

    header {
        position: static;
    }
}

nav ul {
    text-transform: uppercase;
    padding-left: 0;
}

nav ul li {
    list-style-type: none;
    margin: 0;
}

nav ul li a {
    color: black;
    font-weight: bold;
    text-decoration: none;
    padding: 1em;
}

nav ul li a.active {
    color: #9eb1bb;
}

nav ul li a.active:hover {
    text-decoration: none;
    cursor: default;
}

.control-bar .logout-button {
    text-align: center;
    font-size: 100%;
    background-image: url("../images/life.png");
    background-color: rgba(255,255,255,0.6);
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2.75em;
    padding-left: 2em;
    border-radius: 2em;
    background-repeat: no-repeat;
    background-size: 4em 4em;
    background-position: calc(100% + 18px) calc(100% + 10px);
}

.contentWrapper.home .mainBackground:before {
    background-image: url("../images/flowers/home-flower-border-1-low.png");
}

.contentWrapper.home .mainBackground:after  {
    background-image: url("../images/flowers/home-flower-border-2.png");
}

.contentWrapper.ourstory .mainBackgroundContainer:before  {
     background-image: url("../images/flowers/our-story-flower-border-1-low.png");
 }

.contentWrapper.ourstory .mainBackgroundContainer:after  {
    background-image: url("../images/flowers/our-story-flower-border-2-low.png");
}

.contentWrapper.thewedding .mainBackground:before  {
    background-image: url("../images/flowers/big-day-flower-border-1-low.png");
    bottom: 0;
    top: initial;
    background-position-y: bottom;
}

.contentWrapper.thewedding .mainBackground:after  {
    background-image: url("../images/flowers/big-day-flower-border-2-low.png");
    bottom: 0;
    top: initial;
    background-position-y: bottom;
}

.contentWrapper.photos .mainBackground:before {
    background-image: url("../images/flowers/photos-flower-border-1.png");
    margin-top: 20%;
}

.contentWrapper.photos .mainBackground:after  {
    background-image: url("../images/flowers/photos-flower-border-2.png");
    margin-top: 20%;
}

.contentWrapper.celebrations .mainBackground:before  {
    background-image: url("../images/flowers/celebrations-flower-border-1.png");
    background-position-x: right;
}

.contentWrapper.celebrations .mainBackground:after  {
    background-image: url("../images/flowers/celebrations-flower-border-2.png");
    background-position-x: left;
}

.contentWrapper.messages .mainBackground:before {
    background-image: url("../images/flowers/messages-flower-border-1-low.png");
    margin-top: 20%;
}

.contentWrapper.messages .mainBackground:after  {
    background-image: url("../images/flowers/messages-flower-border-2-low.png");
    margin-top: 20%;
}

.contentWrapper.thankyou .mainBackground:before  {
    background-image: url("../images/flowers/thankyou-flower-border-1.png");
}

.contentWrapper.thankyou .mainBackground:after  {
    background-image: url("../images/flowers/thankyou-flower-border-2-low.png");
    opacity: 0.5;
}


@media (min-width: 1221px) {

    .protected-controls {
        display: inline-block;
        margin-left: 1em;
    }

    .hamburger, h1.mainPageTitle {
        display: none;
    }

    nav ul {
        padding: 0;
        margin: 0;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    nav ul li:not(:last-child):after {
        content: "•";
        display: inline-block;
        color: lightgray;
    }

    nav ul li {
        display: inline;
        font-size: 115%;
    }

    nav ul li.logout-button-line {
        display: none;
    }

    nav ul li a {
        display: inline-block;
    }
}

@media (max-width: 1220px) {

    header {
        padding-bottom: 1em;
    }

    .header-bar {
        padding-left: 1em;
    }

    .header-bar, .control-bar, .protected-controls {
        display: flex;
    }

    .control-bar {
        margin-top: 0.75em;
    }

    h1.mainPageTitle {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        line-height: 1.5em;
        -webkit-transition: opacity 0.2s ease-in;
        -o-transition: opacity 0.2s ease-in;
        transition: opacity 0.2s ease-in;
    }

    .hamburger {
        font: inherit;
        font-size: 100%;
        display: inline-block;
        overflow: visible;
        margin: 0;
        cursor: pointer;
        -webkit-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: .15s;
        -o-transition-duration: .15s;
        transition-duration: .15s;
        -webkit-transition-property: opacity,-webkit-filter;
        transition-property: opacity,-webkit-filter;
        -o-transition-property: opacity,filter;
        transition-property: opacity,filter;
        transition-property: opacity,filter,-webkit-filter;
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
    }

    .hamburger-box {
        position: relative;
        display: inline-block;
        width: 4em;
        height: 2.4em;
    }

    .hamburger-inner {
        top: 50%;
        display: block;
        margin-top: 0.2em;
    }

    .hamburger--collapse .hamburger-inner {
        top: auto;
        bottom: 0;
        -webkit-transition-delay: .13s;
        -o-transition-delay: .13s;
        transition-delay: .13s;
        -webkit-transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        -o-transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        -webkit-transition-duration: .13s;
        -o-transition-duration: .13s;
        transition-duration: .13s;
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 4em;
        height: 0.2em;
        -webkit-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        -webkit-transition-duration: .15s;
        -o-transition-duration: .15s;
        transition-duration: .15s;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        border-radius: 0.4em;
        background-color: black;
    }

    .hamburger-inner:after, .hamburger-inner:before {
        display: block;
        content: "";
    }

    .hamburger-inner:before {
        top: -1em;
    }

    .hamburger-inner:after {
        bottom: -1em;
    }

    .hamburger--collapse .hamburger-inner:before {
        -webkit-transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,-webkit-transform .13s cubic-bezier(.55,.055,.675,.19);
        transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,-webkit-transform .13s cubic-bezier(.55,.055,.675,.19);
        -o-transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
        transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
        transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19),-webkit-transform .13s cubic-bezier(.55,.055,.675,.19);
    }

    .hamburger--collapse .hamburger-inner:after {
        top: -2em;
        -webkit-transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
        -o-transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
        transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
    }

    .hamburger--collapse.is-active .hamburger-inner {
        -webkit-transition-delay: .22s;
        -o-transition-delay: .22s;
        transition-delay: .22s;
        -webkit-transition-timing-function: cubic-bezier(.215,.61,.355,1);
        -o-transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        -webkit-transform: translate3d(0,-10px,0) rotate(-45deg);
        transform: translate3d(0,-10px,0) rotate(-45deg);
    }

    .hamburger--collapse.is-active .hamburger-inner:before {
        top: 0;
        -webkit-transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,-webkit-transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,-webkit-transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        -o-transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s,-webkit-transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .hamburger--collapse.is-active .hamburger-inner:after {
        top: 0;
        -webkit-transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
        -o-transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
        transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
        opacity: 0;
    }

    nav {
        text-align: right;
        position: sticky;
    }

    header.menu-shown ~ .content, header.menu-shown ~ .content + footer  {
        display: none;
    }

    .menu-shown h1.mainPageTitle {
        opacity: 0;
        transition: opacity 0s;
        max-height: 1em;
    }

    nav.menu-hidden ul {
        z-index: -1;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: 0;
    }

    nav.menu-hidden .logout-button {
        display: none;
    }

    nav ul {
        text-align: center;
        background: url("../images/lights-low.png") no-repeat;
        background-size: auto 170px;
        background-position: 0 0;
        padding-top: calc(170px + 2em);
        margin-left: -1em;
        margin-right: -1em;
    }

    nav ul li {
        font-size: 130%;
    }

    nav ul li.logout-button-line {
        display: none;
    }

    nav ul li a {
        display: block;
    }

    nav.menu-hidden ul li:nth-child(even) a {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    nav.menu-hidden ul li:nth-child(odd) a {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }

    nav.menu-shown ul li a {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    nav.menu-shown ul li:nth-child(2) a {
        -webkit-transition: -webkit-transform 0.1s ease;
        transition: -webkit-transform 0.1s ease;
        -o-transition: transform 0.1s ease;
        transition: transform 0.1s ease;
        transition: transform 0.1s ease, -webkit-transform 0.1s ease;
    }
    nav.menu-shown ul li:nth-child(3) a {
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    nav.menu-shown ul li:nth-child(4) a {
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    }
    nav.menu-shown ul li:nth-child(5) a {
        -webkit-transition: -webkit-transform 0.4s ease;
        transition: -webkit-transform 0.4s ease;
        -o-transition: transform 0.4s ease;
        transition: transform 0.4s ease;
        transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    }

    nav.menu-shown ul li:nth-child(6) a {
        -webkit-transition: -webkit-transform 0.5s ease;
        transition: -webkit-transform 0.5s ease;
        -o-transition: transform 0.5s ease;
        transition: transform 0.5s ease;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
        -webkit-animation-name: bounceThirdLastAtEnd;
        animation-name: bounceThirdLastAtEnd;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }

    nav.menu-shown ul li:nth-child(7) a {
        -webkit-transition: -webkit-transform 0.6s ease;
        transition: -webkit-transform 0.6s ease;
        -o-transition: transform 0.6s ease;
        transition: transform 0.6s ease;
        transition: transform 0.6s ease, -webkit-transform 0.6s ease;
        -webkit-animation-name: bounceSecondLastAtEnd;
        animation-name: bounceSecondLastAtEnd;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }

    nav.menu-shown ul li:nth-child(8) a {
        -webkit-transition: -webkit-transform 0.7s ease;
        transition: -webkit-transform 0.7s ease;
        -o-transition: transform 0.7s ease;
        transition: transform 0.7s ease;
        transition: transform 0.7s ease, -webkit-transform 0.7s ease;
        -webkit-animation-name: bounceLastAtEnd;
        animation-name: bounceLastAtEnd;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }

    @-webkit-keyframes bounceThirdLastAtEnd{
        25%{
            -webkit-transform: translateX(0.1%);
            transform: translateX(0.1%);
        }
        50%{
            -webkit-transform: translateX(-0.1%);
            transform: translateX(-0.1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes bounceThirdLastAtEnd{
        25%{
            -webkit-transform: translateX(0.1%);
            transform: translateX(0.1%);
        }
        50%{
            -webkit-transform: translateX(-0.1%);
            transform: translateX(-0.1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @-webkit-keyframes bounceSecondLastAtEnd{
        25%{
            -webkit-transform: translateX(-1%);
            transform: translateX(-1%);
        }
        50%{
            -webkit-transform: translateX(1%);
            transform: translateX(1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes bounceSecondLastAtEnd{
        25%{
            -webkit-transform: translateX(-1%);
            transform: translateX(-1%);
        }
        50%{
            -webkit-transform: translateX(1%);
            transform: translateX(1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @-webkit-keyframes bounceLastAtEnd{
        25%{
            -webkit-transform: translateX(1%);
            transform: translateX(1%);
        }
        50%{
            -webkit-transform: translateX(-1%);
            transform: translateX(-1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes bounceLastAtEnd{
        25%{
            -webkit-transform: translateX(1%);
            transform: translateX(1%);
        }
        50%{
            -webkit-transform: translateX(-1%);
            transform: translateX(-1%);
        }
        100%{
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    .hamburger,  .languageToggle, .protected-controls .logout-button {
        margin-left: 0.75rem;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }

    .hamburger,  .protected-controls .logout-button {
        margin-right: 1em;
    }

}

@media (max-width: 925px) {

    .protected-controls .logout-button {
        display: none;
    }

    nav ul li.logout-button-line {
        display: block;
        margin-bottom: 1.25em;
        margin-top: 0.5em;
    }
}

@media (max-width: 700px) {

    header:not(.menu-shown) {
        margin-left: -1em;
        margin-right: -1em;
        border-left: 1em white solid;
        border-right: 1em white solid;
    }

    header:not(.menu-shown):after, header:not(.menu-shown):before {
        content: " ";
        height: 10em;
        width: 1em;
        display: block;
        margin-left: -2em;
        top: 100%;
        position: absolute;
        background-color: transparent;
        background-image: -moz-linear-gradient(white, rgba(255,255,255,0.001));
        background-image: -webkit-linear-gradient(white, rgba(255,255,255,0.001));
        background-image: -o-linear-gradient(white, rgba(255,255,255,0.001));
        background-image: -ms-linear-gradient(white, rgba(255,255,255,0.001));
        background-image: linear-gradient(white, rgba(255,255,255,0.001));
    }

    header:not(.menu-shown):after {
        left: initial;
        right: 0;
        margin-right: -1em;
    }
}


@media (max-width: 650px) {

    h1.mainPageTitle span {
        display: block;
        max-width: 5em;
    }

    .control-bar {
        font-size : 90%;
    }

}

@media (max-width: 475px) {

    .control-bar {
        font-size : 70%;
    }

}
