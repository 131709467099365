.photoBackground {
    background-image: url("../images/background-photo.jpg");
}

.backgroundElements.photo {
    background-image: url("../images/camera-element-photo.png");
    height: 8em;
    width: 8em;
    margin-bottom: -2em;
    margin-top: 1em;
}

.showZoomButton {
    display: inline-block;
    margin-right: 3.5em;
}

p + .galleryContainer {
    margin-top: 4em;
}

@media (pointer: coarse) {

    .galleryInstructions.hoverInstruction {
        display: none;
    }

    p + .galleryContainer {
        margin-top: 0;
    }
}


.church-1.image {
    background-image: url("../images/photos/Church/1_church_2.jpg");
}

.church-2.image {
    background-image: url("../images/photos/Church/1_steps.jpg");
}

.church-3.image {
    background-image: url("../images/photos/Church/2_decorations_1.jpg");
}

.church-4.image {
    background-image: url("../images/photos/Church/2_decorations_1c.jpg");
}

.church-5.image {
    background-image: url("../images/photos/Church/2_decorations_1a.jpg");
}

.church-6.image {
    background-image: url("../images/photos/Church/5_entry_3.jpg");
}

.church-7.image {
    background-image: url("../images/photos/Church/5_close_down_aisle.jpg");
}

.church-8.image {
    background-image: url("../images/photos/Church/9_end_7.jpg");
}

.church-9.image {
    background-image: url("../images/photos/Church/7_readings_2.jpg");
}

.church-10.image {
    background-image: url("../images/photos/Church/7_readings_c.jpg");
}

.church-11.image {
    background-image: url("../images/photos/Church/7_readings_1.jpg");
}

.church-12.image {
    background-image: url("../images/photos/Church/8_rings_6.jpg");
}

.church-13.image {
    background-image: url("../images/photos/Church/10_kiss_3.jpg");
}

.church-14.image {
    background-image: url("../images/photos/Church/11_exit_2.jpg");
}

.church-15.image {
    background-image: url("../images/photos/Church/11_exit_3.jpg");
}

.church-16.image {
    background-image: url("../images/photos/Church/11_exit_4.jpg");
}

.church-17.image {
    background-image: url("../images/photos/Church/11_exit_5a.jpg");
}

.church-18.image {
    background-image: url("../images/photos/Church/11_exit_5d.jpg");
}

.church-19.image {
    background-image: url("../images/photos/Church/11_exit_5f.jpg");
}

.church-20.image {
    background-image: url("../images/photos/Church/11_exit_6.jpg");
}

.church-21.image {
    background-image: url("../images/photos/Church/12_champagne_1a.jpg");
}

.church-22.image {
    background-image: url("../images/photos/Church/12_champagne_1c.jpg");
}



.location-1-1.image {
    background-image: url("../images/photos/Location 1/grass1.jpg");
}

.location-1-2.image {
    background-image: url("../images/photos/Location 1/grass3.jpg");
}

.location-1-3.image {
    background-image: url("../images/photos/Location 1/grass7.jpg");
}

.location-1-4.image {
    background-image: url("../images/photos/Location 1/grass8c.jpg");
}

.location-1-5.image {
    background-image: url("../images/photos/Location 1/grass28.jpg");
}

.location-1-6.image {
    background-image: url("../images/photos/Location 1/grass9.jpg");
}

.location-1-7.image {
     background-image: url("../images/photos/Location 1/grass15.jpg");
}

.location-1-8.image {
    background-image: url("../images/photos/Location 1/grass16.jpg");
}

.location-1-9.image {
    background-image: url("../images/photos/Location 1/grass50.jpg");
}

.location-1-10.image {
    background-image: url("../images/photos/Location 1/grass44.jpg");
}

.location-1-11.image {
    background-image: url("../images/photos/Location 1/grass66.jpg");
}

.location-1-12.image {
    background-image: url("../images/photos/Location 1/grass45.jpg");
}

.location-1-13.image {
    background-image: url("../images/photos/Location 1/grass41.jpg");
}

.location-1-14.image {
    background-image: url("../images/photos/Location 1/grass72.jpg");
}


.location-2-1.image {
    background-image: url("../images/photos/Location 2/1-walking-away-3.jpg");
}

.location-2-2.image {
    background-image: url("../images/photos/Location 2/1a-aa-walking-away-2.jpg");
}

.location-2-3.image {
    background-image: url("../images/photos/Location 2/3-aa-together-far.jpg");
}

.location-2-4.image {
    background-image: url("../images/photos/Location 2/3a-aa-back.jpg");
}

.location-2-5.image {
    background-image: url("../images/photos/Location 2/3b-aa-together-hand-on-face.jpg");
}

.location-2-6.image {
    background-image: url("../images/photos/Location 2/3c-aa-hair.jpg");
}

.location-2-7.image {
    background-image: url("../images/photos/Location 2/4c-aa-together-looking-at-each-other.jpg");
}

.location-2-8.image {
    background-image: url("../images/photos/Location 2/4b-aa-together-kiss-close.jpg");
}

.location-2-9.image {
    background-image: url("../images/photos/Location 2/4-aa-together-another-kiss-2.jpg");
}

.location-2-10.image {
    background-image: url("../images/photos/Location 2/5f-aa-together-laughing-2.jpg");
}

.location-2-11.image {
    background-image: url("../images/photos/Location 2/7b-aa-betsy-looking-straight.jpg");
}

.location-2-12.image {
    background-image: url("../images/photos/Location 2/8-aa-ivan-solo.jpg");
}

.location-2-13.image {
    background-image: url("../images/photos/Location 2/8c-aa-walking-yellow-flowers.jpg");
}

.location-2-14.image {
    background-image: url("../images/photos/Location 2/6b-aa-bouquet.jpg");
}

.location-2-15.image {
    background-image: url("../images/photos/Location 2/6a-aa-together-betsy-smiling.jpg");
}

.location-2-16.image {
    background-image: url("../images/photos/Location 2/9a-aa-twirling5.jpg");
}

.location-2-17.image {
    background-image: url("../images/photos/Location 2/9b-aa-twirling3.jpg");
}

.location-2-18.image {
    background-image: url("../images/photos/Location 2/8e-aa-boots3.jpg");
}


.location-3-1.image {
    background-image: url("../images/photos/Location 3/2d-ivan_solo_5.jpg");
}

.location-3-2.image {
    background-image: url("../images/photos/Location 3/1d-lying_in_glass_bottle.jpg");
}

.location-3-3.image {
    background-image: url("../images/photos/Location 3/1a-lying_in_grass_close_up_2.jpg");
}

.location-3-4.image {
    background-image: url("../images/photos/Location 3/1b-lying_in_grass_close_up.jpg");
}

.location-3-5.image {
    background-image: url("../images/photos/Location 3/1e-lying_on_grass_2.jpg");
}

.location-3-6.image {
    background-image: url("../images/photos/Location 3/1e-lying_on_grass.jpg");
}

.location-3-7.image {
    background-image: url("../images/photos/Location 3/1f-lying_in_grass_shoes_2.jpg");
}

.location-3-8.image {
    background-image: url("../images/photos/Location 3/2a-lying_in_grass_standing_up.jpg");
}

.location-3-9.image {
    background-image: url("../images/photos/Location 3/2b-lying_in_grass_standing_up_2.jpg");
}

.location-3-10.image {
    background-image: url("../images/photos/Location 3/2e-shoes_on_road_5.jpg");
}

.location-3-11.image {
    background-image: url("../images/photos/Location 3/2f-shoes_on_road_1.jpg");
}

.location-3-12.image {
    background-image: url("../images/photos/Location 3/2g-ring_and_back.jpg");
}

.location-3-13.image {
    background-image: url("../images/photos/Location 3/2gg-close_up_5.jpg");
}

.location-3-14.image {
    background-image: url("../images/photos/Location 3/3a-close_up_2.jpg");
}

.location-3-15.image {
    background-image: url("../images/photos/Location 3/4a-dancing_in_road_3.jpg");
}

.location-3-16.image {
    background-image: url("../images/photos/Location 3/4c-dancing_in_road_10.jpg");
}

.location-3-17.image {
    background-image: url("../images/photos/Location 3/4d-dancing_in_road_12.jpg");
}

.location-3-18.image {
    background-image: url("../images/photos/Location 3/4f-dancing_in_road_6.jpg");
}

.location-3-19.image {
    background-image: url("../images/photos/Location 3/4i-dancing_in_road_5.jpg");
}

.location-3-20.image {
    background-image: url("../images/photos/Location 3/4k-scarf_over_shoulders_2.jpg");
}

.location-3-21.image {
    background-image: url("../images/photos/Location 3/4k-scarf_over_shoulders.jpg");
}

.location-3-22.image {
    background-image: url("../images/photos/Location 3/4l-kiss_2.jpg");
}

.location-3-23.image {
    background-image: url("../images/photos/Location 3/5a-facing_away_3.jpg");
}

.location-3-24.image {
    background-image: url("../images/photos/Location 3/6-extra_close_up.jpg");
}

.location-3-25.image {
    background-image: url("../images/photos/Location 3/6b-facing_away_2.jpg");
}



.candid-1.image {
    background-image: url("../images/photos/Candids/license.jpg");
}

.candid-2.image {
    background-image: url("../images/photos/Candids/collecting-bouquet.jpg");
}

.candid-3.image {
    background-image: url("../images/photos/Candids/cake.jpg");
}

.candid-4.image {
    background-image: url("../images/photos/Candids/toppers.jpg");
}

.candid-5.image {
    background-image: url("../images/photos/Candids/cookies.jpg");
}

.candid-6.image {
    background-image: url("../images/photos/Candids/betsy-on-road.jpg");
}

.candid-7.image {
    background-image: url("../images/photos/Candids/ivan-on-road.jpg");
}

.candid-8.image {
    background-image: url("../images/photos/Candids/both-on-road.jpg");
}

.candid-9.image {
    background-image: url("../images/photos/Candids/new-orleans.jpg");
}

.candid-10.image {
    background-image: url("../images/photos/Candids/new-york.jpg");
}

.image.first-title,
.image.second-title,
.image.third-title {
    overflow-y: auto;
}

.image.first-title:after {
    content: "With that, we said goodbye to Staci & Jason.";
}

.galleryContainer.es .image.first-title:after {
    content: "Con eso, nos despedimos de Staci & Jason."
}

.image.second-title:after {
    content: "But if you've made it this far ... Here are a few last candid shots ->";
}

.galleryContainer.es .image.second-title:after {
    content: "Pero si has llegado hasta aquí ... Estas son algunas de las fotos que hicimos con el móvil ->";
}

.image.third-title:after {
    content: "That's all folks!";
}

.galleryContainer.es .image.third-title:after {
    content: "Esto es todo amigos!";
}

.photo-zoom-box.zoomed.modal-visible .image.first-title:after,
.photo-zoom-box.zoomed.modal-visible .image.second-title:after,
.photo-zoom-box.zoomed.modal-visible .image.third-title:after {
    background-color: white;
    padding: 2em;
    left: 0;
    top: calc(50% - 6em);
    width: calc(100% - 4em);
}

.image.first-title:after,
.image.second-title:after,
.image.third-title:after {
    position: absolute;
    top: calc(50% - 2em);
    left: 25%;
    font-size: 250%;
    line-height: 1.5em;
    width: 50%;
    text-align: center;
    font-family: "FerinittaWeb", "Times New Roman", serif;
    padding-bottom: 2em;
}

.image.third-title:after {
    top: calc(50% - 1em);
    font-size: 300%;
}

@media (max-width: 1000px) {

    .image.second-title:after {
        top: calc(50% - 3em);
    }

}

@media (max-width: 700px) {

    .image.first-title:after {
        font-size: 150%;
        top: calc(50% - 1.5em);
    }

    .image.second-title:after {
        font-size: 150%;
        top: calc(50% - 2.5em);
    }

    .image.third-title:after {
        font-size: 200%;
        top: calc(50% - 1em);
    }
}

@media (max-width: 400px) {

    .image.third-title:after {
        top: calc(50% - 2em);
    }
}