@import url("./modal-background.css");

.iframe-container-hidden > * {
    display: none;
}

.iframe-container-visible > * {
    display: block;
    position: fixed;
}

.iframe-container-visible .iframeContainer {
    background-color: black;
    top: 0;
    left: 0;
    height: calc(100vh - 2em);
    width: calc(100vw - 3em);
    margin: 1em;
    box-sizing: content-box;
    z-index: 1200;
}


.iframe-container-visible .closeButton {
    top: -1em;
    right: -1em;
}

.iframe-container-visible .iframeContainer iframe {
    width: 100%;
    height: 100%;
}


@media (max-width: 900px) and (orientation: landscape) {

    .iframe-container-visible .iframeContainer {
        height: 100vh;
        width: 100vw;
        margin: 0;
    }

    .iframe-container-visible .closeButton {
        top: 0.25em;
        right: 1.5em;
    }
}