.messagesBackground {
    background-image: url("../images/background-messages.jpg");
}

.newMessageSection .editableMessage, .messagesSection li {
    border: 1px solid;
    margin-top: 2em;
    padding: 2em;
    border-color: #305b94;
    background-color: rgba(255,255,255,0.1);
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
}

.newMessageSection .name label {
    vertical-align: top;
}

.newMessageSection .editableMessage,
.messagesSection li.editable {
    background-color: rgba(255,255,255,0.5);
    border-width: 2px;
    border-style: dashed;
}

.messagesSection li .content .staticValue {
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
}

section.subSection.newMessageSection:nth-child(odd) .editableMessage,
.messagesSection li:nth-child(even) {
    border-color: #f4c536;
    border-radius: 15px 225px 15px 255px/255px 15px 225px 15px;
}

.messagesSection li.message:nth-child(even) .content .staticValue {
    border-radius: 15px 225px 15px 255px/255px 15px 225px 15px;
}

.messagesSection ul {
    padding: 0;
}

.messagesSection li {
    list-style: none;
}

.messageDate,
.newMessageSection .content,
.newMessageSection .name,
.message .content,
.message .name {
    display: block;
    text-align: left;
}

.messageDate .date {
    display: inline-block;
    margin-right: 0.5em;
}

.messageDate label, .messageDate .dateAndTime,
.newMessageSection .name label, .newMessageSection .name .value,
.message .name label, newMessageSection .name .value {
    display: inline-block;
}

.messageDate,
.newMessageSection .content,
.newMessageSection .name,
.message .content {
    margin-bottom: 0.75em;
}

.editableMessage .name a.toggle.edit {
    padding-left: 0.5em;
}

.editableMessage .editableField.content .value {
    display: block;
    width: 100%;
}

.editableMessage .editableField textarea {
    width: 100%;
    height: 6em;
    box-sizing: border-box;
}

.message .content .staticValue {
    background-image: url("../images/aged-grid-paper.jpg");
    white-space: pre-wrap;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 2em 1em;
    font-family: "Special Elite", Courier, serif;
    font-weight: normal;
}

.editableMessage .content .staticValue {
    width: calc(100% - 6.5em);
}

.backgroundElements.dragonfly {
    background-image: url("../images/dragonfly-low.png");
    height: 10em;
    width: 25em;
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.8;
}


@media (max-width: 700px) {

    .newMessageSection .editableMessage, .messagesSection li {
        margin-left: -2.5em;
        margin-right: -2.5em;
    }

    .editableMessage .name a.toggle.edit {
        padding-left: 0;
    }

    .editableMessage .name .value, .editableMessage .name input {
        display: block;
        width: 100%;
    }
}