.celebrateBackground {
    background-image: url("../images/background-celebrate.jpg");
}

section.content .subSection.celebration {
    padding: 4em 2em;
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(255,255,255,0.4);
}

section.celebration h2 {
    text-align: center;
}

.celebration .details {
    display: table;
    width: 100%;
}

.celebration p.detail {
    display: table-row;
    text-align: left;
}

.celebration p.detail label, .celebrationBox p.detail .detail-description {
    display: table-cell;
    padding: 0.5em;

}

.celebration p.detail label {
    width: 45%;
}

.celebration p.detail label {
    text-align: right;
}

.celebration p.detail .detail-description {
    text-align: left;
}

.backgroundElements.stars {
    background-image: url("../images/stars-elements-celebrate.png");
    width: 30em;
    height: 5.5em;
    background-position-y: bottom;
}


