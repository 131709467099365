@import url("./modal-background.css");

.gallery-image .image-with-options {
    height: 100%;
}

.gallery-image-container .gallery-image .image:not(:last-child) {
    height: calc(100% - 3.5em);
    margin-bottom: 1.5em;
}

.photo-box-container {
    position: relative;
}

.photo-box-container > .magnifyButton,
.showZoomButton:after {
    position: absolute;
}

.photo-box-container > .magnifyButton {
    bottom: 0.5em;
    right: 0.5em;
}

.photo-box-container.zoom-disabled {
    pointer-events: none;
}

.photo-box-container.zoom-disabled:hover .magnifyButton {
    display: none;
}

.image-options-bar {
    padding: 0.5em;
}

.image-options-bar > * {
    display: block;

}

.magnifyButton, .showZoomButton:after,
.likeButton, .unLikeButton,
.showHeartButton:after {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 2.5em;
    background-position: 50% 50%;
    background-repeat: no-repeat;

}


.magnifyButton, .showZoomButton:after {
    width: 2.5em;
    height: 2.5em;
    display: block;
    background-size: 90%;
    background-color: rgba(255,255,255,0.5);
    background-image: url("../images/magnify.png");
}

.showZoomButton:after {
    content: " ";
    display: inline-block;
    margin-left: 0.5em;
    margin-top: -0.25em;
}


.likeButton, .unLikeButton, .showHeartButton:after {
    display: none;
    width: 2.25em;
    height: 2.25em;
    border: 2px solid white;
    bottom: 0.5em;
    background-size: 60%;
    background-color: #dc8b3b;
    margin-top: 0.25em;
}

.likeButtonContainer.liked .unLikeButton,
.likeButtonContainer.notLiked .likeButton {
    display: block;
}

.showHeartButton:after {
    display: inline-block;
    content: " ";
    position: static;
    margin-left: 0.5em;
    vertical-align: bottom;
}

.likeButton,
.showHeartButton:after {
    background-image: url("../images/heart-empty.png");
}

.unLikeButton {
    background-image: url("../images/heart-full.png");
}

.likeList {
    font-family: "Special Elite", "Courier New", serif;
    text-align: center;
    color: #9eb1bb;
}

.photo-zoom-box > * {
    position: fixed;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

.photo-zoom-box.not-zoomed > * {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
}

.photo-zoom-box.zoomed > * {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.photo-zoom-box .photo-container {
    background-color: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1200;
}

.photo-zoom-box .photo-container .image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.photo-zoom-box .photo-container .closeButton {
    top: 1em;
    right: 2em;
}

.photo-zoom-box.left > * {
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}

.photo-zoom-box.center > * {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.photo-zoom-box.left ~ .content .with-zoom,
.photo-zoom-box.left ~ .content .with-gallery .image-options-bar {
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}

.photo-zoom-box.center ~ .content .with-zoom,
.photo-zoom-box.center ~ .content .with-gallery  .image-options-bar {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.photo-zoom-box ~ .content .with-gallery .image-options-bar {
    opacity: 1;
    -webkit-transition: opacity 0.5s, -webkit-transform 0.75s;
    transition: opacity 0.5s, -webkit-transform 0.75s;
    -o-transition: opacity 0.5s, transform 0.75s;
    transition: opacity 0.5s, transform 0.75s;
    transition: opacity 0.5s, transform 0.75s, -webkit-transform 0.75s;
}

.photo-zoom-box.zoomed ~ .content .with-gallery  .image-options-bar {
    opacity: 0;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.75s;
    transition: opacity 0.5s, -webkit-transform 0.75s;
    -o-transition: opacity 0.5s, transform 0.75s;
    transition: opacity 0.5s, transform 0.75s;
    transition: opacity 0.5s, transform 0.75s, -webkit-transform 0.75s;
}


.photo-zoom-box.zoomed ~ .content .with-zoom {
    opacity: 0 !important;
    -webkit-transform: scale(1.3) !important;
    -ms-transform: scale(1.3) !important;
    transform: scale(1.3) !important;
    -webkit-transition: opacity 0.5s, -webkit-transform 0.75s !important;
    transition: opacity 0.5s, -webkit-transform 0.75s !important;
    -o-transition: opacity 0.5s, transform 0.75s !important;
    transition: opacity 0.5s, transform 0.75s !important;
    transition: opacity 0.5s, transform 0.75s, -webkit-transform 0.75s !important;
}


@media (max-width: 800px) {

    .photo-zoom-box .photo-container {
        /*max-width: calc(100vw - 1em);*/
    }

}

@media (max-width: 650px) {

    .mainBackground .magnifyButton {
        display: none;
    }

}


@media (pointer: fine) {

    .photo-box-container .magnifyButton,
    .likeButton, .unLikeButton {
        opacity: 0;
    }

    .photo-box-container:hover .magnifyButton,
    .photo-box-container:hover .unLikeButton,
    .photo-box-container:hover .likeButton {
        opacity: 0.8;
    }

    .photo-box-container .magnifyButton:hover,
    .photo-box-container .likeButton:hover,
    .photo-box-container .unLikeButton:hover {
        cursor: pointer;
        opacity: 1;
    }

    .photo-box-container .magnifyButton:hover {
        background-color: rgba(255,255,255,0.8);
    }
}

@media (pointer: coarse) and (max-width: 700px) {

    .mainBackground .photo-box-container .magnifyButton,
    .mainBackgroundContainer .photo-box-container .magnifyButton {
        display: none;
    }

}