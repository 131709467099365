@import url("./modal-background.css");

.message-hidden > * {
    display: none;
}

.message-visible > * {
    display: block;
    position: fixed;
}

.message-visible .message {
    top: calc(50vh - 200px);
    left: calc(50vw - 160px);
    height: 400px;
    width: 320px;
    background-color: white;
    border: 1px solid black;
    z-index: 1200;
    padding: 2em;
    box-sizing: border-box;
}

.message-visible .message.info {
    top: calc(50vh - 240px);
    height: 480px;
}

.message-visible .message p {
    border-top: 1px solid;
    padding-top: 1.5em;
    margin-top: -1em;
    padding-bottom: 1em;
}

.message.confirmation .buttons button {
    min-width: 4em;
}

.message.confirmation .buttons button:last-child {
    margin-left: 2em;
}