.loginForm {
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255,255,255,0.5);
    padding: 2em;
    top: 1.5em;
    padding-bottom: 2.5em;
    min-width: 320px;
}

.loginForm:after {
    content: " ";
    display: block;
    position: absolute;
    opacity: 0.25;
    pointer-events: none;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/life.png");
    background-position-y: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.loginForm h2 {
    text-align: center;
    padding: 0.75em;
    margin: 0;
}

.loginForm p {
    line-height: 1.5em;
    text-align: justify;
    padding: 1em;
    margin: 0;
}

.loginForm p.smallPrint {
    margin-top: 2rem;
    font-size: 75%;
    background-color: rgba(255, 255, 255, 0.7);
}

.loginForm p.smallPrint .intro {
    font-weight: bold;
    color: black;
}


div#firebaseui_container {
    margin-top: 2em;
}

.locale-es .firebaseui-idp-text-long {
    color: transparent !important;
}

.locale-es .firebaseui-idp-text-long:after {
    color: white;
    position: absolute;
    left: 3.5em;
    pointer-events: none;
}

.locale-es .firebaseui-idp-google .firebaseui-idp-text-long:after {
    color: #757575;
    content: "Acceder con Google";
}

.locale-es .firebaseui-idp-password .firebaseui-idp-text-long:after {
     content: "Acceder con email";
 }

.locale-es .firebaseui-idp-facebook .firebaseui-idp-text-long:after {
    content: "Acceder Facebook";
}

.locale-es .firebaseui-idp-twitter .firebaseui-idp-text-long:after {
    content: "Acceder con Twitter";
}

button.guest-login-button {
    margin-top: 1.25em;
    position: relative;
    z-index: 100;
}

.firebaseui-container {
    position: relative;
    z-index: 100;
}


.firebaseui-container.firebaseui-id-page-callback {
    position: unset;
    background-color: transparent;
    box-shadow: none;
}

.firebaseui-container.firebaseui-id-page-callback:after {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    height: 100vh;
    width: 100vw;
    z-index: 150;
}

.mdl-progress.firebaseui-busy-indicator:before {
    content: "Please wait - we're signing you in ...";
    font-family: "Montserrat", Arial, sans-serif;
    margin-top: 2em;
    height: 100%;
    width: 100%;
    text-align: center;
    display: block;

}

div#firebaseui_container:empty + button {
    display: none;
}

.mdl-progress.firebaseui-busy-indicator:after,
div#firebaseui_container:empty:after {
    content: " ";
    display: block;
    background: url("../images/flower-loading.gif") no-repeat;
    padding-bottom: 12.5em;
    background-position-x: center;
    background-position-y: center;
    background-size: 17em;
    margin-top: 2em;
}

.locale-es .mdl-progress.firebaseui-busy-indicator:after {
    content: "Por favor, espera - accediendo ...";

}

@media (max-width: 420px) {

    .loginForm {
        margin-left: -2em;
        margin-right: -2em;
        width: 100%;
        min-width: calc(320px - 2em);
    }

}